<template>
    <div>
        <header-portrate /> 
        <exports-print ref="exportbar" />

        <div style="margin-bottom:70px !important;">
            <bread-crumbs :items="items"></bread-crumbs> 
            <tabs-comp :tabs="tabs" />
            <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:20px !important;`">
                <v-col
                cols="12"
                md="10"
                sm="12">
                <form autocomplete="off">
                    <v-row>
                        <v-col cols="12" md="2" sm="12">
                            <label>{{lang.start_date}}</label>
                            <b-input-group>
                            <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#c3d9ff;" v-model="sd.day" :options="daysList"/>
                            <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                            <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#c3d9ff;" v-model="sd.year" :options="yearsList" />
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" md="2" sm="12">
                            <label>{{lang.end_date}}</label>
                            <b-input-group>
                            <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#c3d9ff;" v-model="ed.day" :options="daysList" />
                            <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                            <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#c3d9ff;" v-model="ed.year" :options="yearsList" />
                            </b-input-group>
                        </v-col>

                        <v-col cols="12" md="2" sm="12">
                            <label></label>
                            <b-input-group style="margin-top:-5px">
                            <b-button variant="light" style="background:blue;color:#fff;width:75px;"  @click="getReport()">{{lang.search}}</b-button>
                            </b-input-group>
                        </v-col>
                    </v-row>
                </form>
                <b-input-group class="mt-3" style="display:none"> 
                    <b-form-input placeholder="Start Date" style="border:1px solid #000;border-radius: 0;" type="date" v-model="sdate"></b-form-input>
                    <b-form-input placeholder="End Date" style="border:1px solid #000" type="date" v-model="edate"></b-form-input>
                    <b-input-group-append style="border:1px solid #000">
                        <b-button variant="light" style="background: gold !important;font-size:14px;color:#000;font-size:14px;" @click="getReport()"><i class="fas fa-search" style="color:000;font-weight:bold;"></i></b-button>
                    </b-input-group-append>
                </b-input-group>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="text-right" style="display:none"> 
                    <b-button variant="light" style="width:145px;margin-right:9px" class="mytab mytab1 mt-3" @click="exportE('e')">{{lang.export_excel}}</b-button>
                    <b-button variant="light" style="width:145px;margin-right:3px;background:red;color:#FFF;display:none" class="mytab mt-3" @click="exportE('p')">PDF تصدير</b-button>
                </v-col> 
            </v-row>
            <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-10px !important;`">
                <v-col cols="12" md="12" sm="12">
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th colspan="7" class="text-center backBlack" style="border-bottom:7px solid #fff !important;">{{lang.income_expense_report}}</th>
                            </tr>
                            <tr>
                                <th class="text-center backBlack">{{lang.date}}</th>
                                <th class="text-center backGreen">{{lang.income}}</th>
                                <th class="text-center backRed">{{lang.purchase}}</th>
                                <th class="text-center backRed">{{lang.expnese}}</th>
                                <th class="text-center backBlack">{{lang.cash}}</th>
                                <th class="text-center backBlack">{{lang.other}}</th>
                                <th class="text-center backBlack">{{lang.balance}}</th>
                            </tr>
                            <tr>
                                <th class="text-left" colspan="6">{{lang.old_balance}}</th>
                                <th class="text-center">{{lastBalance}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in report" :key="index">
                                <td class="text-center" style="direction:ltr;">{{item.date}}</td>
                                <td class="text-center" style="direction:ltr;background:lightgreen">{{item.income}}</td>
                                <td class="text-center" style="direction:ltr;background:lightgreen">{{item.purchase}}</td>
                                <td class="text-center" style="direction:ltr;background:lightgreen">{{item.outcome}}</td>
                                <td class="text-center" style="direction:ltr;">{{item.cash}}</td>
                                <td class="text-center" style="direction:ltr;">{{item.others}}</td>
                                <td class="text-center" style="direction:ltr;" v-html="parseFloat(item.balance)"></td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th class="backBlack text-center">{{lang.total}}</th>
                                <th class="backGreen text-center">{{totals.inTotal}} </th>
                                <th class="backRed text-center">{{totals.purchase}} </th>
                                <th class="backRed text-center">{{totals.outTotal}} </th>
                                <th class="backBlack text-center">{{totals.cash}} </th>
                                <th class="backBlack text-center">{{totals.others}} </th>
                                <th class="backEmpty text-center"></th>
                            </tr>
                        </tfoot>
                    </v-simple-table>
                </v-col>
            </v-row>
        </div>
        <Footer />
    </div>
</template>

<script>

import BreadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import {mapActions, mapGetters} from 'vuex'
import axios from 'axios'
import ExportsPrint from '@/components/exports-print.vue'
export default{
    name: 'InOutReport',
    components: {BreadCrumbs, TabsComp, HeaderPortrate, Footer,ExportsPrint},
    data() {
        return {
            items: {},
            // sdate: '',
            // edate: '',
            inoutRows: [],
            sd: {
                day: 1,
                month: 1,
                year: (new Date()).getFullYear(),
            },
            ed: {
                day: (new Date()).getDay() + 1,
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            }
        }
    },
    computed:{ 
        ...mapGetters({
            inOutState: `inOutReport/inOutState`
        }),
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        tabs: function() {
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        report: function () {
            return this.inOutState.report
        },
        totals: function(){
            let t = {
                inTotal: 0,
                purchase: 0,
                outTotal: 0,
                cash: 0,
                others: 0,
            }
            for(const item in this.report){
                t.inTotal = parseFloat(t.inTotal) + parseFloat(this.report[item].income);
                t.purchase = parseFloat(t.purchase) + parseFloat(this.report[item].purchase);
                t.outTotal = parseFloat(t.outTotal) + parseFloat(this.report[item].outcome);
                t.cash = parseFloat(t.cash) + parseFloat(this.report[item].cash);
                t.others = parseFloat(t.others) + parseFloat(this.report[item].others);
            }
            return t;
        },
        lastBalance: function(){
            let t = 0;
            for(const item in this.report){
                t = parseFloat(this.report[item].balance) - parseFloat(this.report[item].income) + parseFloat(this.report[item].outcome) + parseFloat(this.report[item].purchase)
                break
            }
            return t;
        },
        headers: function() {
            return [
                { text: this.lang.date },
                { text: this.lang.income },
                { text: this.lang.purchase },
                { text: this.lang.expnese },
                { text: this.lang.cash },
                { text: this.lang.other },
                { text: this.lang.balance },
                { text: this.lang.balance },
            ]
        },
        sdate: function() {
            return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
        },
        edate: function() {
            return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        yearsList: function(){
            const start_year = this.$store.state.licenseType.startYear;
            let t = [{text: this.lang.year, value: -1}];
            for(let i=0;i<100;i++){
            if(parseInt(start_year) + i <= (new Date()).getFullYear()){
                t.push({text: start_year + i, value: start_year+i})
            }
            
            }
            return t
        },
        daysList: function(){
            return [
            {text: this.lang.day, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            {text: 13, value: 13},
            {text: 14, value: 14},
            {text: 15, value: 15},
            {text: 16, value: 16},
            {text: 17, value: 17},
            {text: 18, value: 18},
            {text: 19, value: 19},
            {text: 20, value: 20},
            {text: 21, value: 21},
            {text: 22, value: 22},
            {text: 23, value: 23},
            {text: 24, value: 24},
            {text: 25, value: 25},
            {text: 26, value: 26},
            {text: 27, value: 27},
            {text: 28, value: 28},
            {text: 29, value: 29},
            {text: 30, value: 30},
            {text: 31, value: 31},
            ]
        },
        monthsList: function(){
            return [
            {text: this.lang.chose_month, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            ]
        },
    },
    methods:{

        preparedata(){
            let t = this.headers;
            let data = this.report;
            // console.log("hi it ",this.report)
            let hdata = [];
            let exdata = [];
            for(let i=0;i<t.length;i++){
                hdata.push(t[i].text);
            }
            exdata.push(hdata);
            exdata.push([
                "","","","",this.lang.old_balance,this.lastBalance
            ]);
            Object.values(data).forEach(value => {
                let x = [];
                x.push(value.date);
                x.push(value.income);
                x.push(value.purchase);
                x.push(value.outcome);
                x.push(value.cash);
                x.push(value.others);
                x.push(parseFloat(value.balance));
                exdata.push(x);
            })

            exdata.push([
                this.lang.total,this.totals.inTotal,this.totals.outTotal,this.totals.cash,this.totals.others,""
            ]);
            this.$refs.exportbar.exportdata = exdata;
            this.$refs.exportbar.sdate = this.sdate;
            this.$refs.exportbar.edate = this.edate;
            this.$refs.exportbar.exporttitle = this.lang.income_expense_report;
            // if(this.selected == 1)
            //     this.$refs.exportbar.exporttitle = this.lang.ment_req_cards_new;
            // if(this.selected == 4)
            //     this.$refs.exportbar.exporttitle = this.lang.ment_req_cards_late;
            // if(this.selected == 3)
            //     this.$refs.exportbar.exporttitle = this.lang.ment_req_cards_closed;
            // this.$refs.exportbar.palte_number = this.plate_number;
            // this.$refs.exportbar.contact_number = this.mobile;
        },
        ...mapActions({
            getInOutRep: `inOutReport/getInOutRep`
        }),
        getReport() {
            this.getInOutRep({sdate:this.sdate,edate:this.edate})
        },
        async exportE(e){
            const post = new FormData();
            post.append('export','incomereport');
            // console.log(this.report)
            let i = 0;
            for(const repo in this.report){
                
                for(const rep in this.report[repo]){
                    post.append('data['+i+']['+rep+']',this.report[repo][rep]);
                }
                i++;
            }
            post.append('sdate',this.sdate);
            post.append('edate',this.edate);
            post.append('startbalance',this.lastBalance);
            post.append('auth',this.$cookies.get('SAMCCCC-Cookies'));
            const response = await axios.post(
                '/api/excel.php',post
            )

            if(response && response.data){
                window.open("/api/"+response.data.url)
            }
        }
    },
    created(){
        this.getInOutRep({sdate:this.sdate,edate:this.edate})
    }
}
</script>